.App {
  text-align: center;
  background: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@font-face {
  font-family: 'Fight';
  src: url('./fight.ttf') format('truetype');
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 50px;
  font-weight: 700;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.967) 0px 0px 10px;
  /* Updated to control visibility dynamically */
  opacity: 0; 
  transition: opacity 2s ease-in-out;
  font-family: 'Fight', sans-serif; /* Applying it to a specific class */

}

.smalltext {
  font-size: 24px;
  font-weight: 500;
  color: white;
}

.App-header {
  background-image: url("mayhem.webp");
  background-color: rgba(255, 0, 0, 0);
  background-blend-mode: multiply;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
}

.joinButton {
  border: 10px solid white;
  color: white;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.2s, color 0.2s;
  text-decoration: none;
  display: inline-block;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Fight', sans-serif; /* Applying it to a specific class */
  transform:rotate(5deg)
}

.joinButton:hover {
  background-color: #F8A1D8;
  color: white;
}

.startButton{
  border: 10px solid white;
  color: white;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: 700;
  padding: 10px;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.2s, color 0.2s;
  text-decoration: none;
  display: inline-block;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Fight', sans-serif; /* Applying it to a specific class */
}

.startButton:hover {
  background-color: white;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  border: 5px solid white;
  border-radius: 50%;
}

.getStarted {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image:url("bg.jpg");
  background-size:cover;
}

/* Ensure that only the visible class makes text visible */
.visible {
  opacity: 1 !important;
}
